@import '~bootstrap/scss/bootstrap';

.b4bd {
  > .display-1 {
    margin-top: 24px;
  }

  > .row {
    margin-top: 48px;
  }

  @include media-breakpoint-up(sm) {
    > .display-1 {
      margin-top: 96px;
    }

    > .row {
      margin-top: 120px;

      &.reverse {
        flex-direction: row-reverse;
      }
    }
  }
}
